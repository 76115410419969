import React from "react";
import FooterOne from "../components/Footer/FooterOne";
import HeaderOne from "../components/Header/HeaderOne";
import PageLoader from "../components/PageLoader/PageLoader";
import cn from "classnames";
import ThreeDBackground from "../components/Banner/3dBackground";

const LayoutOne = (props) => {
  return (
    <div className={cn("")} style={{position:"relative"}}>
        {/* <div style={{height: "100vh", width: "100%", position: "fixed", zIndex: 0}}>
        <ThreeDBackground/>
        </div>      */}
      <PageLoader />

      <HeaderOne />

      {props.children}

      <FooterOne />
    </div>
  );
};

export default LayoutOne;
