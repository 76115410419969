import $ from "jquery"
export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })
}

export const handleClickScroll = (id) => {
  const element = document.getElementById(id)
  if (element) {
    //Menu Toggle Btn
    $("body").removeClass("mobile-menu-visible")
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({behavior: "smooth"})
  }
}
