import React from "react";
import one from './workers/6.jpg'
import two from './workers/10.jpg'
import four from './workers/8.jpg'
import five from './workers/32.jpg'
import six from './workers/25.jpg'
import seven from './workers/9.jpg'
import eight from './workers/30.png'
import nine from './workers/29.png'
import ten from './workers/11.jpg'
import eleven from './workers/34.jpg'
import twelve from './workers/26.jpg'
import thirteen from './workers/31.jpg'
import fourteen from './workers/28.jpg'
import fifteen from './workers/33.jpg'
import mike from './workers/duuk.jpg'

import TeamOneItem from "./TeamOneItem";

const TeamOne = () => {
  const team_members = [
    {
      src: one,
      name: "Ө. Содгэрэл",
      designation: "Chief Technology Officer (CTO)",
    },
    {
      src: two,
      name: "А. Оюунбат",
      designation: "Senior developer",
    },
    {
      src: six,
      name: "Г. Ган-Оргил",
      designation: "Senior developer",
    },
    {
      src: seven,
      name: "З. Зүмбэрэл",
      designation: "Senior developer",
    },
    {
      src: eight,
      name: "Г. Ариунбилэг",
      designation: "Developer",
    },
    {
      src: twelve,
      name: "Б. Мөнх-Эрдэнэ",
      designation: "Developer",
    },
    {
      src: thirteen,
      name: "Б. Дөлгөөн",
      designation: "Developer",
      duuk: mike
    },
    {
      src: five,
      name: "Н. Жавхлан",
      designation: "Data analyst",
    },
    {
      src: nine,
      name: "Ц. Номиндарь",
      designation: "Designer",
    },
    {
      src: four,
      name: "Ш. Мягмарнаран",
      designation: "System analyst",
    },
    {
      src: fourteen,
      name: "Б. Алтанзагас",
      designation: "System analyst",
    },
    {src: fifteen,
      name: "Г. Ган-Эрдэнэ",
      designation: "System tester"
      },
   
 
  
    {
      src: ten,
      name: "А. Долгорсүрэн",
      designation: "Research worker",
    },
   
    {
      src: eleven,
      name: "О. Нямдорж",
      designation: "Intern",
    },
  ];

  return (
    <section className="team-area pt-130" id="team-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-70">
              <span className="sub-title">Бид хэн бэ?</span>
              <h2 className="title">
                Манай <span>хамт</span> олон
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {team_members.map((member, index) => (
            <div key={index} className="col-xl-3 col-md-4 col-sm-6">
              <TeamOneItem item={member} index={index}/>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
