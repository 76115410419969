import request from '../lib/request'

const submitPortal= (data)=>{
    return (request({
        url   : `/portal/submitForum`,
        method: 'POST',
        data: data
    }))
}

const OrganizationService = {
    submitPortal
}

export default OrganizationService;
