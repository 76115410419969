import React, {useState} from "react"
import organizationService from "../../service/feedbackService"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const ContactOneForm = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [feedback, setFeedback] = useState("")

  const submitHandler = async (e) => {
    try {
      e.preventDefault()
      let result = await organizationService.submitPortal({
        is_org: 0,
        nu_email: email,
        nu_name: email,
        org_id: 613,
        question: feedback,
        feedback_type_id: 1,
      })
      console.log("result", result)
      if (result?.code == 200) {
        toast('Таны хүсэлтийг амжилттай илгээлээ', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success"
          });
          setEmail("")
          setFeedback("")
          setName("")
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="contact-form-wrap">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-grp">
              <input
                type="text"
                placeholder="Нэр"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-grp">
              <input
                type="email"
                placeholder="И-мэйл"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-grp">
          <textarea
            name="massage"
            value={feedback}
            placeholder="Санал хүсэлт"
            onChange={(e) => setFeedback(e.target.value)}
          ></textarea>
        </div>
        <div className="submit-btn text-center">
          <button type="submit" className="btn">
            Хүсэлт илгээх
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactOneForm
