import React from "react";
import { Link } from "react-router-dom";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import FloatingMac from "./FloatingMac";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area px-3 banner-bg">
     <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6" style={{height: "100%", padding: 0}}>
          <FloatingMac/>
          
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">DATALAND TECHNOLOGY LLC</span>
                <h2 className="title">
                Бизнесээ <span>аюулгүй өндөр</span>  хүртээмжтэй болгомоор байна уу ?
                </h2>  
              </div>
              <p>
              Танай байгууллагыг Монголын хамгийн амжилттай компаниудын нэг болгохын тулд манай мэргэшсэн инженер, мэдээллийн технологийн мэргэжилтнүүд хамтран ажиллахад бэлэн байна.
              </p>
              <a href="#contact" className="btn">
              ХОЛБОГДОХ
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
