import React from "react"
import cn from "classnames"

const ContactOneInfoItem = (props) => {
  return (
    <div className="contact-info-item">
      <a href={props.item.url} target="_blank" rel="noreferrer">
        <div className="icon">
          <span className="icon-background"></span>
          <i className={cn("fas", props.item.icon)}></i>
        </div>
      </a>
      <div className="content">
        <p>{props.item.content}</p>
      </div>
    </div>
  )
}

export default ContactOneInfoItem
