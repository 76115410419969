import React, {useEffect, useState} from "react"
import santa from "../../assets/img/images/santa.png"
const TeamOneItem = (props) => {
  const [isValue, setIsValue] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const toggleValue = () => {
    setIsValue((prevValue) => !prevValue)
  }

  useEffect(() => {
    const randomInterval = Math.floor(Math.random() * 100000) + 1000 // Random interval between 1 to 5 seconds (in milliseconds)
    const intervalId = setInterval(toggleValue, randomInterval)

    return () => {
      clearInterval(intervalId) // Clear the interval when the component unmounts
    }
  }, [])

  useEffect(() => {
    const resetTimer = setTimeout(toggleValue, 1000) // Reset to the original value after 1 second

    return () => {
      clearTimeout(resetTimer) // Clear the reset timeout when the component unmounts
    }
  }, [])

  return (
    <div
      className="team-item"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={`team-thumb ${isValue && "running"}`}>
        {/* <img src={santa} className="santa_hat"/> */}
        <img
          src={
            // props.index === 6
              // ? isHover
                // ? props.item.duuk
                // : props.item.src
              // : 
              props.item.src
          }
          loading="lazy"
          height={197}
          width={197}
          style={{objectFit: "cover"}}
          alt={props.item.name}
        >
        </img>
      </div>
      <div className="team-content">
        <h2 className="title">{props.item.name}</h2>
        <span>{props.item.designation}</span>
      </div>
    </div>
  )
}

export default TeamOneItem
