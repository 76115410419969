import React from "react"
import ContactOneForm from "./ContactOneForm"
import ContactOneInfoItem from "./ContactOneInfoItem"

const ContactOne = () => {
  const info_items = [
    {
      icon: "fa-envelope",
      content: <>info@dataland.mn</>,
      url: 'mailto:info@dataland.mn'
    },
    {
      icon: "fa-phone",
      content: (
        <>        
        +976 7507 7171
        </>
      ),
      url: 'tel:97675077171'
    },
    {
      icon: "fa-map-marker-alt",
      content: <>Ayud tower, 10 давхарт 1003 тоот</>,
      url: 'https://www.google.com/maps/search/?api=1&query=Ayud tower, 10 давхарт 1003 тоот'
    },
  ]

  return (
    <section id="contact" className="contact-area pt-70 pb-110">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-70">
              <span className="sub-title">Холбоо барих</span>
              <h2 className="title">
                <span>Бидэнтэй</span> холбогдох
              </h2>
            </div>
          </div>
        </div>

        {/* secction info */}
        <div className="contact-info-wrap">
          <div className="row justify-content-center">
            {info_items.map((x, index) => (
              <div key={index} className="col-lg-4 col-sm-6">
                <ContactOneInfoItem item={x} />
              </div>
            ))}
          </div>
        </div>
        {/* section form */}
        <ContactOneForm />
      </div>
    </section>
  )
}

export default ContactOne
