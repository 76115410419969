import axios from 'axios'
// export const ApiServer = "https://mofa.opengov.mn"
export const ApiServer = "https://staging.opengov.mn"


const client = axios.create({
  baseURL: `${ApiServer}/api`
});

client.defaults.headers.post['Accept'] = 'application/json';

export const baseUrl = () => {
  return `${ApiServer}/api`
}



const responseSuccessHandler = response => {
  return response.data;
};

const responseErrorHandler = error => {
  if (!error.response) {
    alert('NETWORK ERROR')
  } else {
      return Promise.reject(error)
    }
}

client.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);

export default client;
