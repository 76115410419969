import React from "react"
import {Suspense} from "react"
import {Canvas} from "@react-three/fiber"
import Carousel from "./Carousel"

function HeroSection() {
  return (
    <div style={{position: "relative"}} className="heroSection">
      <Canvas>
        <Suspense fallback={null}>
          <Carousel />
        </Suspense>
      </Canvas>
    </div>
  )
}

export default HeroSection
