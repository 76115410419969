import React from "react"
import {Link} from "react-router-dom"
import logoImage from "../../../src/assets/img/logo/logo.png"
import {scrollToTop} from "../../lib/helpers"

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-bottom">
            <div className="footer-scroll-wrap">
              <button
                className="scroll-to-target"
                data-target="html"
                onClick={scrollToTop}
              >
                <i className="fas fa-arrow-up"></i>
              </button>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>
                    © 2023 Dataland Technology LLC, Бүх эрх хуулиар
                    хамгаалагдсан.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 d-flex"
                style={{justifyContent: "flex-end", gap: "2rem"}}
              >
                <a href="https://www.facebook.com/DatalandTechnologyLLC" target="_blank" rel="noreferrer">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.linkedin.com/company/dataland-technology-llc/about/" target="_blank" rel="noreferrer">
                  <i class="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterOne
